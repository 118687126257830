.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.center-div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.logout-btn {
    padding: 0 30px;
    margin-left: 20px;
    font-size: 16px;
}

.main-container {
    background-color: black;
    background-image: url('../assets/spotify-rot.svg');
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: 650px;
    color: white;
}

.app-header {
    background-color: #000000;
    margin-bottom: 20px;
}

.sp-cont {
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 1100px) {
    .sp-cont {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (max-width: 500px) {
    .app-header {
        font-size: 10px;
    }
}

@media screen and (max-width: 400px) {
    .logout-btn {
        padding: 0 20px;
        margin-left: 10px;
        font-size: 10px;
    }
}