.song-subtext {
    font-size: 12px;
}

.song-name {
    font-size: 17px;
    text-align: left;
    width: 90%;
}

.song-stack {
    align-items: flex-start;
    margin-left: 20px;
    width: 100%;
}

@media screen and (max-width: 1100px) {
    .song-name {
        font-weight: bold;
    }
}

@media screen and (max-width: 550px) {
    .song-name {
        font-size: 13px;
    }

    .song-subtext {
        font-size: 10px;
    }
}