.Collapsible {
    background-color: #2e4536;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
}

.active {
    background-color: #1cc457 !important;
}

.playlist-title {
    font-size: 20px;
    position: absolute;
}

.Collapsible__contentInner {
    background-color: #181818;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
}

.playlist-trigger {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.status-lozenge {
    margin-left: auto;
    margin-right: 0;
    display: flex;
    align-items: flex-end;
    justify-content: end;
}

.playlist-stack {
    align-items: flex-start;
    margin-left: 20px;
}

.playlist-avatar {
    width: 60px !important;
    height: 60px !important;
}

.playlist-expand {
    margin-left: auto;
}

.playlist-subtext {
    font-size: 12px;
    text-align: start;
}

.pic-cont .MuiPaper-root {
    margin-bottom: 2px;
    padding: 5px 0;
}

.add-songs-btn {
    padding: 8px 0;
    font-size: 20px;
    margin-top: 20px;
}

.playlist-cont {
    width: 40vw;
}

@media screen and (max-width: 1100px) {
    .playlist-cont {
        width: 95vw;
    }
}