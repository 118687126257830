.liked-card .MuiPaper-root {
    margin-bottom: 7px;
}

.liked-header .MuiTypography-root {
    font-size: 25px;
    font-weight: bold;
    font-family: 'Poppins';
}

.liked-cont {
    width: 40vw;
}

@media screen and (max-width: 1100px) {
    .liked-cont {
        width: 95vw;
        padding: 0 !important;
    }
}