.login-container {
    background-color: black;
    background-image: url('../assets/spotify-rot.svg');
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: 650px;
}

.login-form {
    background-color: #121212;
    padding: 50px;
    color: white;
    border-radius: 15px;
}

.login-text {
    font-size: 30px;
    margin-bottom: 20px;
}