.App {
  text-align: center;
}

.button {
  border-radius: 30px;
  background-color: #1cc457;
  font-size: 25px;
  padding: 10px 80px 10px 80px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button:hover {
  background-color: #2e874d;
}

.button svg {
  margin-right: 10px;
}

div {
  font-family: 'Poppins';
}